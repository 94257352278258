import { createApp } from 'vue'
import { getWS, postWS } from '../js/transaction.js'
import debounce from 'lodash.debounce';

const redirectUrl = `https://${process.env.APPDOMAIN}/dashboard/`;

async function main() {
  const app = createApp({
    data() {
      return {
        user: undefined,
        isReady: false,
        passwordType: 'password',
        loginEmail: '',
        loginPassword: '',
        rememberMe: true,
        pageState: 'none',
        forgotEmail: '',
        forgotCode: '',
        signupPassword: '',
        signupEmail: '',
        signupConfirmPassword: '',
        signupPasswordStrength: 0,
        signupPasswordStrengthColor: 'progress-error',
        signupPasswordConfirmColor: 'input-error',
        signupPasswordStrengthDebounce: debounce(() => {
          this.calcPasswordStrength();
        }),
        modalTitle: 'Info',
        modalBody: ''
      }
    },
    computed: {
      // a computed getter
      isLoggedIn() {
        // `this` points to the component instance
        return this.user !== undefined
      }
    },
    watch: {
      signupPassword(oldVal, newVal) {
        this.$nextTick(() => {
          this.signupPasswordStrengthDebounce();
        });
      },
      signupConfirmPassword(oldVal, newVal) {
        this.$nextTick(() => {
          this.signupPasswordStrengthDebounce();
        });
      }
    },
    methods: {
      load() {
        let data = localStorage.getItem('loginRememberMe');
        if (data) {
          this.rememberMe = data==='true';
        }
        if (this.rememberMe) {
          data = localStorage.getItem('loginEmail');
          this.loginEmail = data;
        }
      },
      save() {
        localStorage.setItem('loginRememberMe', this.rememberMe ? 'true' : 'false');
        if (this.rememberMe) {
          localStorage.setItem('loginEmail', this.loginEmail);
        }
        else {
          localStorage.removeItem('loginEmail');
        }
      },
      setPageState(pageState) {
        this.pageState = pageState;
        this.$nextTick(() => {
          if (pageState === 'login') {
            if (this.loginEmail !== '') {
              this.$refs.loginPassword.focus();
            }
            else {
              this.$refs.loginEmail.focus();
            }
          }
          if (pageState === 'forgot') {
            this.$refs.forgotEmail.focus();
          }
          if (pageState === 'forgotCode') {
            this.$refs.forgotCode.focus();
          }
          if (pageState === 'signup') {
            this.$refs.signupEmail.focus();
          }
        })
      },
      calcPasswordStrength() {
        const password = this.signupPassword;
        let score = 0;
        if (password.length >= 8) {
          score += 20;
        }
        score += /[A-Z]/.test(password) ? 20 : 0;
        score += /[a-z]/.test(password) ? 20 : 0;
        score += /\d/.test(password) ? 20 : 0;
        score += /\W/.test(password) ? 20 : 0;

        this.signupPasswordStrength = score;
        this.signupPasswordStrengthColor = 'progress-error';
        if (score >= 50) {
          this.signupPasswordStrengthColor = 'progress-warning';
        }
        if (score == 100) {
          this.signupPasswordStrengthColor = 'progress-success';
        }
        this.signupPasswordConfirmColor = this.signupPassword === this.signupConfirmPassword ? 'input-success' : 'input-error';
      },
      forgotSendCode() {
        this.setPageState('forgotCode');
      },
      async signupSubmit() {
        const options = {
          buttonId: 'signupSubmit'
        }
        const result = await postWS('/ws/user/signup', {
          email: this.signupEmail,
          password: this.signupPassword
        },
        options);
        if (result.success) {
          window.location = '/dashboard/';
        }
        else {
          if (result.error === 'User already exists') {
            this.modalBody = `${this.signupEmail} is already registered with HelloAI. Maybe you meant to login instead? Click the login link above.`
            document.getElementById('modal').showModal()
          }
          else if (result.error === 'Disposable email') {
            this.modalBody = `HelloAI does not allow some email providers. Please enter a different email address.`
            document.getElementById('modal').showModal()
          }
          else {
            this.modalBody = `An unknown error ocurred. Try again. "${result.error}"`
            document.getElementById('modal').showModal()
          }
        }
      },
      async loginSubmit() {
        this.save();
        const options = {
          buttonId: 'loginSubmit'
        }
        const result = await postWS('/ws/user/login', {
          email: this.loginEmail,
          password: this.loginPassword
        },
        options);
        if (result.success) {
          window.location = '/dashboard/';
        }
        else {
          if (result.error === 'Invalid credentials') {
            this.modalBody = `Incorrect email or password. If you forgot your password click the link "Forgot my password"`
            document.getElementById('modal').showModal()
          }
          else {
            this.modalBody = `An unknown error ocurred. Try again. "${result.error}"`
            document.getElementById('modal').showModal()
          }
        }
      }
    }
  }).mount('#app')

  app.load();

  const userResult = await getWS('/ws/user');

  app.isReady = true;

  if (userResult.user) {
    app.user = userResult.user;
  }
}
main();
